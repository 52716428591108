import { WishlistElementHeadless } from 'https://cdn.jsdelivr.net/npm/@appmate/wishlist@4.29.2/wishlist-element-headless.js';

class WishlistButtonHeadless extends WishlistElementHeadless {
  getStateConfig() {
    return {
      productInfo: true,
      wishlist: true,
    };
  }

  getEventConfig() {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('wishlist')) {
        this.handleClick(false);
      }
    }, 2000);

    return {
      'click': this.handleClick,
    };
  }

  handleClick(autoRemove = true) {
    if (this.productInfo.inWishlist) {
      if (autoRemove) {
        window.dispatchEvent(new CustomEvent('notify', {
          detail: {
            message: 'Product removed from wishlist',
            type: 'wishlist',
            icon: 'heart',
          },
        }));
        return this.app.removeWishlistItem(this.productInfo);
      }

      return;
    } else {
      const accessMode = this.app.settings.general.wishlistAccessMode;

      if (accessMode === 'REQUIRE_LOGIN_TO_VIEW_LIST' || accessMode === 'UNRESTRICTED' || accessMode === 'REQUIRE_LOGIN_BEFORE_USE' && this.app.customer) {
        const message = this.host.getAttribute('data-message');
        window.dispatchEvent(new CustomEvent('notify', {
          detail: {
            message: message,
            type: 'wishlist',
            icon: 'heart',
          },
        }));
      }

      return this.app.addWishlistItem(this.productInfo);
    }
  }

  updated() {
    this.host.classList.toggle('wk-selected', this.productInfo.inWishlist);

    const textElement = this.host.querySelector('.wk-text');
    textElement.innerText = this.getText();
  }

  getText() {
    if (this.productInfo.inWishlist) {
      return 'In Wishlist';
    }
    return 'Add to Wishlist';
  }
}

window.headlessElements.define('wishlist-button-headless', WishlistButtonHeadless);